<template>
  <div>
    <v-card class="mx-auto">
      <v-card-title class="blue-grey lighten-5 justify-space-between">
        <span class="font-weight-bold">Measurements</span>

        <v-text-field
          v-model="searchTerm.term"
          @keydown.enter="search()"
          @click:clear="$store.commit('measurements/setSearchResult', false)"
          :loading="status.searching"
          placeholder="Search Public Food Key"
          append-icon="mdi-magnify"
          style="max-width: 350px"
          class="mr-5"
          hide-details
          clearable
          rounded
          dense
          solo
        ></v-text-field>

        <v-menu bottom left offset-y transition="slide-x-transition">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item dense @click="addMeasurementAction()">
              <v-list-item-icon class="mr-2"><v-icon small>mdi-plus-circle</v-icon></v-list-item-icon>
              <v-list-item-title>Add</v-list-item-title>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-icon class="mr-2"><v-icon small>mdi-refresh</v-icon></v-list-item-icon>
              <v-list-item-title>Refresh List</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>

      <v-card-text v-if="status.getting">
        <preloader/>
      </v-card-text>

      <v-simple-table
        v-if="!status.getting"
        fixed-header
        dense
      >
        <template slot:default>
          <thead>
            <tr>
              <th>Public Food Key</th>
              <th>Units</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="measurement in result.length ? result : measurements " :key="measurement.id">
              <td>
                <circular v-if="status.deleting.includes(measurement.id)" />
                {{ measurement['Public Food Key'] }}
              </td>
              <td>{{ measurement.units.length }}</td>
              <td width="10">
                <v-menu bottom left offset-y transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      small
                    >
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item dense @click="editMeasurement(measurement)">
                      <v-list-item-icon class="mr-2"><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item dense @click="deleteMeasurement(measurement)">
                      <v-list-item-icon class="mr-2"><v-icon color="red" small>mdi-delete</v-icon></v-list-item-icon>
                      <v-list-item-title> <span class="red--text">Delete</span> </v-list-item-title>
                    </v-list-item>
                  </v-list>

                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-actions class="blue-grey lighten-5" v-if="measurements.length">
        <v-btn
          @click="getMeasurements()"
          :loading="status.loadingMore"
          v-if="measurements.length >= 50"
          class="mr-2"
          color="secondary"
          depressed
          small
        >Load More</v-btn>

        <span class="caption">Showing {{ measurements.length }} items</span>
      </v-card-actions>
    </v-card>

    <AddUpdateMeasurement
      :show="showDialog"
      @cancel="closeDialog()"
    />

    <ConfirmDelete
      :showDialog="showDeleteDialog"
      :message="deleteMessage"
      @cancel="showDeleteDialog = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import AddUpdateMeasurement from '@/components/AddUpdateMeasurement'

export default {
  name: 'Measurements',

  metaInfo: {
    title: 'Measurements'
  },

  data() {
    return {
      showDialog: false,
      showDeleteDialog: false,
      deleteMessage: null,
      toDelete: null,
    }
  },

  components: {
    AddUpdateMeasurement
  },

  computed: {
    ...mapState({
      measurements: state => state.measurements.measurements,
      status: state => state.measurements.status,
      result: state => state.measurements.searchResult,
      searchTerm: state => state.measurements.search,
    })
  },

  methods: {
    ...mapActions('measurements', [
      'getMeasurements',
      'search',
      'delete',
    ]),

    editMeasurement(measurement) {
      this.$store.commit('measurements/setData', measurement)
      this.showDialog = true
    },

    closeDialog() {
      this.showDialog = false
    },

    addMeasurementAction() {
      let data = {
        'Public Food Key' : null,
        units: []
      }

      this.$store.commit('measurements/setData', data)
      this.showDialog = true
    },

    deleteMeasurement(item) {
      this.toDelete = item
      this.deleteMessage = `Are you sure you want to delete measurement ${item['Public Food Key']}?`
      this.showDeleteDialog = true
    },

    deleteConfirmed() {
      this.showDeleteDialog = false
      this.delete(this.toDelete)
    }
  },

  created() {
    if (!this.status.firstLoad) {
      this.getMeasurements()
    }
  }
}

</script>
