<template>
  <v-dialog
    :value="show"
    max-width="650"
    no-click-animation
    persistent
  >
    <v-card>
      <v-card-title class="mb-5">
        <span class="font-weight-bold">
          {{ data.id ? 'Update Measurement' : 'Add Measurement' }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon class="mr-n2 mt-n2" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="measurementForm">
          <v-text-field
            v-model="data['Public Food Key']"
            :rules="[rules.required]"
            label="Public Food Key"
            outlined
          ></v-text-field>

          <v-scroll-x-transition group>
            <v-card
              v-for="(unit, i) in data.units"
              :key="i"
              color="grey lighten-4"
              class="mb-2"
              flat
            >
              <v-card-text>
                <v-btn
                  @click="removeUnit(unit)"
                  class="mr-n6 mt-2"
                  color="white"
                  depressed
                  x-small
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="unit['description']"
                      label="Description"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="unit['grams']"
                      label="Weight in grams"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="unit['mls']"
                      label="Weight in mLs"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-scroll-x-transition>
        </v-form>

        <div class="mt-7 d-flex gap-7">
          <v-btn
            @click="add()"
            :loading="status.adding"
            v-if="!data.id"
            color="accent"
            depressed
          >Add</v-btn>

          <v-btn
            @click="update()"
            :loading="status.updating"
            v-if="data.id"
            color="accent"
            depressed
          >Update</v-btn>

          <v-btn
            @click="$emit('cancel')"
            :disabled="status.updating || status.adding"
            depressed
          >Close</v-btn>

          <v-spacer></v-spacer>

          <v-btn
            @click="insertUnit()"
            color="secondary"
            depressed
            x-small
            fab
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

export default {
  name: 'AddUpdateMeasurement',

  props: {
    show: Boolean
  },

  data() {
    return {
      rules,
    }
  },

  computed: {
    ...mapState({
      data: state => state.measurements.data,
      status: state => state.measurements.status,
    })
  },

  methods: {
    ...mapActions('measurements', [
      'updateMeasurement',
      'addMeasurement',
    ]),

    insertUnit() {
      this.$store.commit('measurements/insertUnit')
    },

    removeUnit(unit) {
      this.$store.commit('measurements/removeUnit', unit)
    },

    update() {
      if (this.$refs.measurementForm.validate()) {
        this.updateMeasurement()
      }
    },

    add() {
      if (this.$refs.measurementForm.validate()) {
        Promise.all([this.addMeasurement()])
        .then(() => {
          this.close()
        })
      }
    },

    close() {
      let data = {
        'Public Food Key' : null,
        units: []
      }

      this.$store.commit('measurements/setData', data)
      this.$store.commit('measurements/setAddingState', false)
      this.$store.commit('measurements/setUpdatingState', false)
      this.$emit('cancel')
    }
  }
}

</script>
